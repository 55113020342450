<template>
  <div>
    <v-row justify="start">
      <v-btn text color="primary" @click="navigateToHintergrundwissen">
        <v-icon>mdi-arrow-left</v-icon>
        Zurück zu: Hintergrundwissen
      </v-btn>
    </v-row>
    <br />

    <h2 id="diagnose">Diagnose</h2>
    <p>
      Nachdem Du Dir das Hintergrundwissen durchgelesen hast, baut nun der
      Diagnoseteil darauf auf:
    </p>
    <p>
      Ein Indikator für eine gute "Zahlvorstellung" ist es, wenn eine Person in
      der Lage ist, sicher zwischen dem notierten Zahlzeichen einer Zahl, dem
      dazugehörigen Zahlwort und der entsprechenden Menge mental hin und her zu
      übersetzen, also die Fähigkeit zum Darstellungswechsel besitzt.
    </p>
    <p>
      Ausschlaggebend für ein tragfähiges Stellenwertverständnis ist jedoch
      nicht nur diese Fähigkeit alleine, sondern auch ein Metawissen über die
      Durchführung der Übersetzungstätigkeiten. Das bedeutet, dass die Person
      weiß und erklären kann, wie die einzelnen Ziffern des Zahlzeichens, die
      Bestandteile des Zahlwortes und die Anzahlen der einzelnen
      Bündelungseinheiten zusammenhängen (vgl. Schulz 2014, S. 150).
    </p>

    <p>
      Betrachte die Kinderdokumente in der Tabelle. Sie wurden nach Fehlertypen
      gruppiert. In den Audiodateien kannst Du Dir, warum sie so gruppiert
      wurden und was die Fehlertypen ausmacht.
    </p>

    <!-- Tabelle und Audios GRUPPEN
https://owncloud.idial.institute/apps/files/?dir=/faledia.all.project/Seiten_wip/SWVI/2%20Diagnose/Gruppendiagnose&fileid=2162865
 die 4 Audios fehlen noch, die sollen jeweils in zu einem Fehlertypen und am besten neben der jeweiligen Kategorie anklickbar sein.
ENDE Tabelle und Audios Gruppen -->

    <!-- <v-card outlined raised style="background-color: red">
      <v-card-title>Tabelle und Audios GRUPPEN</v-card-title>
      <v-card-text>
        <p>https://owncloud.idial.institute/apps/files/?dir=/faledia.all.project/Seiten_wip/SWVI/2%20Diagnose/Gruppendiagnose&fileid=2162865
 die 4 Audios fehlen noch, die sollen jeweils in zu einem Fehlertypen und am besten neben der jeweiligen Kategorie anklickbar sein.</p>
      </v-card-text>
    </v-card>
    <br /> -->

    <p>
      In der nachfolgenden Tabelle siehst Du mehrere Beispiele für
      unterschiedliche Fehler im Stellenwertverständnis.
    </p>
    <v-simple-table class="imageTable">
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="text-center">
              <strong>Probleme mit der Null</strong>
              <vuetify-audio
                ref="audioPlayer5"
                :file="
                  require('@/assets/swvi/Gruppendiagnose/SWV-I_Gruppendiagnose_1_Probleme_mit_der_0.mp3')
                "
                color="primary"
                flat
                :ended="
                  () =>
                    audioEnded(
                      'Gruppendiagnose/SWV-I_Gruppendiagnose_1_Probleme_mit_der_0.mp3'
                    )
                "
                @click.native="
                  () =>
                    audioPlayerClicked(
                      $event,
                      'Gruppendiagnose/SWV-I_Gruppendiagnose_1_Probleme_mit_der_0.mp3',
                      $refs.audioPlayer5
                    )
                "
                class="audioPlayerBigger"
              ></vuetify-audio>
            </td>
            <td>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp1_Dok1.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp1_Dok1.jpg"
                />
              </div>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp1_Dok2.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp1_Dok2.jpg"
                />
              </div>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp1_Dok3.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp1_Dok3.jpg"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>Fehlende Bündelung</strong>
              <vuetify-audio
                ref="audioPlayer6"
                :file="
                  require('@/assets/swvi/Gruppendiagnose/SWV-I_Gruppendiagnose_2_Fehlende_Bündelung.mp3')
                "
                color="primary"
                flat
                :ended="
                  () =>
                    audioEnded(
                      'Gruppendiagnose/SWV-I_Gruppendiagnose_2_Fehlende_Bündelung.mp3'
                    )
                "
                @click.native="
                  () =>
                    audioPlayerClicked(
                      $event,
                      'Gruppendiagnose/SWV-I_Gruppendiagnose_2_Fehlende_Bündelung.mp3',
                      $refs.audioPlayer6
                    )
                "
                class="audioPlayerBigger"
              ></vuetify-audio>
            </td>
            <td>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp2_Dok1.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp2_Dok1.jpg"
                />
              </div>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp2_Dok2.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp2_Dok2.jpg"
                />
              </div>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp2_Dok3.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp2_Dok3.jpg"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>Falsche Bündelungseinheit</strong>
              <vuetify-audio
                ref="audioPlayer7"
                :file="
                  require('@/assets/swvi/Gruppendiagnose/SWV-I_Gruppendiagnose_3_Falsche_Bündelungseinheit.mp3')
                "
                color="primary"
                flat
                :ended="
                  () =>
                    audioEnded(
                      'Gruppendiagnose/SWV-I_Gruppendiagnose_3_Falsche_Bündelungseinheit.mp3'
                    )
                "
                @click.native="
                  () =>
                    audioPlayerClicked(
                      $event,
                      'Gruppendiagnose/SWV-I_Gruppendiagnose_3_Falsche_Bündelungseinheit.mp3',
                      $refs.audioPlayer7
                    )
                "
                class="audioPlayerBigger"
              ></vuetify-audio>
            </td>
            <td>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp3_Dok1.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp3_Dok1.jpg"
                />
              </div>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp3_Dok2.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp3_Dok2.jpg"
                />
              </div>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp3_Dok3.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp3_Dok3.jpg"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>Falsche Reihenfolge der Stellenwerte</strong>
              <vuetify-audio
                ref="audioPlayer8"
                :file="
                  require('@/assets/swvi/Gruppendiagnose/SWV-I_Gruppendiagnose_4_Falsche_Reihenfolge_der_Stellenwerte.mp3')
                "
                color="primary"
                flat
                :ended="
                  () =>
                    audioEnded(
                      'Gruppendiagnose/SWV-I_Gruppendiagnose_4_Falsche_Reihenfolge_der_Stellenwerte.mp3'
                    )
                "
                @click.native="
                  () =>
                    audioPlayerClicked(
                      $event,
                      'Gruppendiagnose/SWV-I_Gruppendiagnose_4_Falsche_Reihenfolge_der_Stellenwerte.mp3',
                      $refs.audioPlayer8
                    )
                "
                class="audioPlayerBigger"
              ></vuetify-audio>
            </td>
            <td>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp4_Dok1.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp4_Dok1.jpg"
                />
              </div>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp4_Dok2.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp4_Dok2.jpg"
                />
              </div>
              <div class="imageTableImages biggerTableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Gruppendiagnose/Fehlertyp4_Dok3.jpg')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Gruppendiagnose/Fehlertyp4_Dok3.jpg"
                />
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <p>
      Insbesondere beim Darstellungswechsel wird oft sichtbar, inwiefern die
      Prinzipien des Bündelns, des Stellenwerts und des Zahlwerts verstanden und
      entsprechend berücksichtigt werden. Das gilt besonders dann, wenn die
      Darstellungen der Zahlen nicht den Konventionen entsprechen. Ein Beispiel
      dafür kann eine bildliche Darstellung von 13 ungebündelten Zehnern sein.
      Insbesondere Aufgaben, in denen ein Darstellungswechsel erforderlich ist
      und kleine Irritationen eingebaut sind, sind geeignet um zu
      diagnostizieren, inwiefern Prinzipien unseres Stellenwertsystems
      verstanden haben und berücksichtigen können.
    </p>

    <p>
      An bearbeiteten Dokumenten von Lernenden wird deutlich, inwiefern durch
      Darstellungswechsel Probleme im Verständnis der Schülerinnen und Schüler
      aufgedeckt werden können. Es ist jedoch anzumerken, dass nicht jede
      Aufgabe, in der ein Darstellungswechsel gefordert ist, zur Diagnose jedes
      der für das Stellenwertverständnis relevanten Prinzipien geeignet ist. Mit
      einer Aufgabe, in der keine Bündelung erforderlich ist, wirst Du auch
      nichts darüber erfahren, inwiefern ein Kind das Bündelungsprinzip bereits
      berücksichtigt und somit vermutlich auch verstanden hat. Ebenso wirst Du
      keine Informationen dazu erhalten, ob ein Kind die Rolle der Null in einem
      Stellenwertsystem versteht, wenn in der Aufgabe kein Stellenwert vorkommt,
      der nicht besetzt ist. Insofern ist die Wahl der Aufgaben für eine
      Diagnose im Hinblick darauf gut abzuwägen, was man erfahren möchte und was
      die gestellten Aufgaben überhaupt für Erkenntnisse liefern können.
    </p>

    <p>
      Bisher lag der Fokus auf der Identifikation typischer Fehler. Im Sinne
      einer förderorientierten Diagnose ist es jedoch unabdingbar, die Ursache
      solcher Fehler näher zu bestimmen. Nur wenn man die Ursache eines Fehlers
      kennt, kann man passende Fördermaßnahmen ergreifen, um die
      Fehlvorstellungen der Kinder aufzuarbeiten.
    </p>

    <!-- Abbildung und Text STEMPELN
 Leider lässt mich die Cloud nicht auf die Dokumente zugreifen. Hier der Ordner, in denen die Dokumente liegen.
 Die Reihenfolge ist: 
1. Bild Clara dazu Text Clara
2. Bild Marie dazu Text Marie
3. Bild Taifun dazu Text Taifun
https://owncloud.idial.institute/apps/files/?dir=/faledia.all.project/Seiten_wip/SWVI/2%20Diagnose/Erläuterungstexte_und_Abbildungen_Lernbaustein_Stempeln_Einzeldiagnose_SWV_ST&fileid=2162575
ENDE Abbildung und Text STEMPELN -->

    <v-simple-table class="imageTable">
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png"
                />
              </div>
            </td>
            <td>
              <p>
                In dieser Abbildung siehst Du, wie Clara drei Aufgabe gelöst
                hat. Anhand der vierten Aufgabe 29 + 6 wird rekonstruiert, wie
                Clara beim Lösen der Aufgaben vermutlich vorgegangen ist, über
                welche Kompetenzen sie bereits verfügt und was wir über die
                Ursache des Fehlers vermuten können.
              </p>
              <p>
                Betrachtet man die Lösungen der oberen drei Aufgaben, so fällt
                auf, dass Clara vermutlich jeweils die beiden Summanden
                stellenweise, dabei eventuell auch die Zehner und Einer
                ziffernweise, betrachtet und separat addiert. Die dabei
                herauskommenden Summen notiert sie hintereinander, anstatt sie
                wiederum stellengerecht miteinander zu addieren. Clara würde bei
                der Aufgabe 29 + 6 also vermutlich zu dem Ergebnis 215 kommen,
                indem sie zuerst 2 + 0 = 2, anschließend 9 + 6 = 15 rechnet und
                beide Teilergebnisse hintereinander aufschreibt.
              </p>
              <p>
                Clara verfügt dementsprechend bereits über die Kompetenz,
                Additionsaufgaben von ein- und zweistelligen Zahlen zu
                zweistelligen Summen stellenweise zu lösen, solange dabei keine
                Bündelung erforderlich ist.
              </p>
              <p>
                Die Ursache für die Fehler könnte sein, dass Clara das Prinzip
                des Stellenwerts und das Prinzip der Bündelung noch nicht
                verstanden hat. Dass Clara das Prinzip des Stellenwerts noch
                nicht verstanden hat, kann daran vermutet werden, dass sie zwei
                Ziffern zur Besetzung eines Stellenwerts notiert. Dies deutet
                auch auf Probleme mit dem Bündelungsprinzip hin, denn Clara
                schreibt zwei Ziffern an eine Stelle, da sie keine Bündelung
                durchführt bzw. Elemente der gleichen Bündelungseinheit (Zehner
                = Z, Einer = E) nicht zusammenfasst. Clara rechnet 9 E + 6 E =
                15 E. Die 15 Einer werden jedoch nicht als 1 Zehner und 5 Einer
                erkannt bzw. der eine Zehner nicht mit den beiden anderen
                Zehnern aus der Rechnung 2 Z + 0 Z = 2 Z zusammengefasst.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"
                />
              </div>
            </td>
            <td>
              <p>
                In der Abbildung siehst Du, wie Marie drei Aufgabe gelöst hat.
                Anhand der vierten Aufgabe 58 + 21 wird rekonstruiert, wie Marie
                beim Lösen der Aufgaben vermutlich vorgegangen ist, über welche
                Kompetenzen sie bereits verfügt und was wir über die Ursache des
                Fehlers vermuten können.
              </p>
              <p>
                Bei der Aufgabe 58 + 21 würde Clara vermutlich zu dem Ergebnis
                61 kommen. Dies lässt sich aus den Lösungen der vorherigen
                Aufgaben schließen, bei denen sie die beiden Ziffern des zweiten
                Summanden schrittweise zum Einer des ersten Summanden addiert.
                Mit dieser Vorgehensweise würde sie bei der Aufgabe 58 + 21 wie
                folgt rechnen: 58 + 2 + 1, womit sie auf die Summe 61 käme.
              </p>
              <p>
                Marie kann bereit einstellige Zahlen korrekt zu zweistelligen
                Zahlen addieren. Beim Addieren einstelliger Zahlen zu
                zweistelligen Zahlen kommt sie prinzipiell zum richtigen
                Ergebnis, wobei ihr auch der Zehnerübergang keine
                Schwierigkeiten bereitet. Das erkennt man zum Beispiel an der
                dritten Lösung: Marie rechnet anstatt 18 + 72 die Aufgabe 18 + 7
                + 2 = 27 und gelangt für ihre gedachte Aufgabe auf die richtige
                Summe.
              </p>
              <p>
                Die Ursache für Maries Fehler könnte sein, dass Marie das
                Prinzip des Stellenwerts noch nicht verstanden hat. Das lässt
                sich anhand dessen vermuten, dass sie die Stellenwerte des
                zweiten Summanden außer Acht lässt, während sie dessen Ziffern
                zum ersten Summanden addiert. Bei der Berechnung der Summe
                behandelt sie beide Ziffern des zweiten Summanden als Einer.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="
                    require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun.png')
                  "
                  contain
                  parentId="SWVI"
                  elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun.png"
                />
              </div>
            </td>
            <td>
              <p>
                In der Abbildung siehst Du, wie Taifun drei Aufgabe gelöst hat.
                Anhand der vierten Aufgabe wird rekonstruiert, wie Taifun beim
                Lösen der Aufgaben vermutlich vorgegangen ist, über welche
                Kompetenzen er bereits verfügt und was wir über die Ursache des
                Fehlers vermuten können.
              </p>
              <p>
                Würde Taifun wie in den vorherigen Lösungen vorgehen, würde er
                vermutlich die Anzahl der dargestellten Einer, nämlich 58, in
                der Einerspalte der Stellenwerttafel notieren. Bei der
                Übersetzung in die symbolische Zahl, würde er alle Ziffern
                ungebündelt aus der Stellenwerttafel abschreiben und somit auf
                die falsche Zahl 20358 kommen.
              </p>
              <p>
                Taifun kann bereits die Anzahl der Tausender, Hunderter, Zehner
                und Einer aus der bildlichen Darstellung korrekt in eine formale
                Darstellung in die Stellentafel übertragen. Das er an dieser
                Stelle noch keine Bündelung vornimmt, ist kein Fehler, da z. B.
                46 in die Einerspalte einzutragen genauso richtig ist wie 4 in
                die Zehner- und 6 in die Einerspalte. Außerdem trägt er die
                Stellen, die keine Elemente in der bildlichen Darstellung
                zeigen, richtig mit einer Null in die Stellenwerttafel ein, die
                er auch in die symbolische Zahlschreibweise überträgt.
              </p>
              <p>
                Die Ursache für seinen Fehler könnte sein, dass er das Prinzip
                des Bündelns und das Prinzip des Stellenwerts noch nicht
                verstanden hat. Das wird daran deutlich, dass Taifun bei
                Anzahlen von mehr als 9 Elementen in einem Stellenwert, diese
                zweistellige Anzahl der Elemente in die Ziffernschreibweise der
                Zahl überträgt, anstatt zu bündeln. So rückt jede nachfolgende
                Ziffer in den nächsthöheren Stellenwert.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <p>
      Die Ursachen von Fehlern können sehr vielfältig sein. Das Erkennen dieser
      Ursachen für Probleme eines Lernenden, bildet die Grundlage für eine
      individuelle und adaptive Förderung. Neben dem Wahrnehmen der Probleme von
      Lernenden kommt vor allem der Ursachenforschung eine enorme Wichtigkeit
      zu. Nur wenn man die Ursachen, das heißt die Unklarheiten und
      Fehlvorstellungen im Verständnis der Lernenden kennt, können diese
      aufgearbeitet und durch ein besseres Verständnis abgebaut werden.
    </p>

    <v-row justify="start">
      <v-btn text color="primary" @click="navigateToHintergrundwissen">
        <v-icon>mdi-arrow-left</v-icon>
        Zurück zu: Hintergrundwissen
      </v-btn>
    </v-row>
    <br />

    <Survey
      assignedSite="swvi"
      broderTopic="swv"
      :topics="[
        'Bündeln und Entbündeln',
        'Stellenwert und Zahlenwert',
        'Darstellungswechsel beim Stellenwertverständnis',
        'Stellenwertverständnis allgemein',
      ]"
      :alreadySend="
        $store.state.user.completedSurveys
          ? $store.state.user.completedSurveys.includes('swvi')
          : false
      "
      :skipLearningStyle="$store.state.user.completedSurveys.length > 0"
    />

    <br/>
    <br/>
    <br/>
    <h4 id="literaturverzeichnis">Literaturverzeichnis</h4>
      <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              Schulz, A. (2014). <i>Fachdidaktisches Wissen von Grundschullehrkräften. Diagnose und Förderung bei besonderen Problemen beim Rechnenlernen.</i> Wiesbaden: Springer Sprektrum.
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import Survey from "@/components/faledia/Survey";
import AppHelper from "@/common/AppHelper";
import AppExpandableImage from "@/common/AppExpandableImage";
export default {
  beforeCreate: function() {
    AppHelper.isUserAllowedRedirectIfNot("swvi");
  },
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
    AppExpandableImage,
    Survey,
  },
  data() {
    return {};
  },
  methods: {
    audioEnded: function(id) {
      AppHelper.trackMatomoEvent(
        this,
        "SWVI",
        "id:33; Audio komplett abgespielt;",
        id
      );
    },
    audioPlayerClicked: function(event, id, ref) {
      if (
        ref.firstPlay &&
        !event.srcElement.classList.contains("v-card__text")
      ) {
        // other than in LernbausteinStempeln a click on the surrounding vcard can trigger an event
        // the first playback can cause an unwated pause event, so we have to check it this way
        AppHelper.trackMatomoEvent(
          this,
          "SWVI",
          "id:32; Audio first play currentTime: " +
            ref.currentTime +
            " duration: " +
            ref.duration +
            ";",
          id
        );
      } else {
        if (event.srcElement.classList.contains("mdi-stop")) {
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:34; Audio stop currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-play")) {
          // der button hat noch das andere logo, es handelt sich aber um pause
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:35; Audio pause currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-pause")) {
          // der button hat noch das andere logo, es handelt sich aber um play
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:36; Audio play currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-volume-mute")) {
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:37; Audio mute currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-volume-high")) {
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:38; Audio unmute currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        }
      }
    },
    videoPlayerEvent: function(event, id) {
      let local_id = 0;
      if (event.type == "play") {
        local_id = 40;
      } else if (event.type == "pause") {
        local_id = 41;
      } else if (event.type == "ended") {
        local_id = 42;
      } else if (event.type == "seeking") {
        local_id = 43;
      } else if (
        event.type == "fullscreenchange" ||
        event.type == "webkitfullscreenchange" ||
        event.type == "mozfullscreenchange" ||
        event.type == "msfullscreenchange"
      ) {
        local_id = 44;
      } else if (event.type == "enterpictureinpicture") {
        local_id = 45;
      } else if (event.type == "leavepictureinpicture") {
        local_id = 46;
      }
      AppHelper.trackMatomoEvent(
        this,
        "SWVI",
        "id:" +
          local_id +
          "; Video " +
          event.type +
          " currentTime: " +
          event.currentTarget.currentTime +
          " duration: " +
          event.currentTarget.duration +
          ";",
        id
      );
    },
    navigateToHintergrundwissen: function() {
      this.$router.push({ name: "swvi" });
    },
  },
};
</script>

<style scoped>
.imageTableImages {
  margin-top: 10px;
  margin-bottom: 10px;
}
.imageTable {
  margin-bottom: 20px;
}

.tableImageContainer {
  width: 179px;
}

.biggerTableImageContainer {
  width: 390px;
}

.audioPlayer {
  width: 179px;
}

.audioPlayerBigger {
  width: 200px;
  margin: auto;
}
</style>
